import * as React from 'react'
import {useState} from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql, Link } from 'gatsby'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation, Controller, Parallax, Mousewheel, Keyboard } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Arrow from '../svg/arrow';
import { EnteringView, LeavingView, EnteringSmall, LeavingSmall } from "../components/cursor"


import Layout from "../components/layout";

const Client = ({ data, pageContext }) => {
    const client = data.client.edges
    const number = data.all.nodes.length
    const next = pageContext.next
            ? {
                url: `/client/${pageContext.next.slug}`,
                name: `${pageContext.next.name}`
            }
            : null
    const previous = pageContext.previous
            ? {
                url: `/client/${pageContext.previous.slug}`,
                name: `${pageContext.previous.name}`
            }
            : null
    const previousBackup = data.all.nodes[number - 1]
    const nextBackup = data.all.nodes[0]


    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [controlledSwiperTitle, setControlledSwiperTitle] = useState(null);
    return (
        <Layout>
            {client.map(client => (
                <main>
                    <HelmetDatoCms 
                        seo={client.node.seoMetaTags}
                        bodyAttributes={{
                            class: 'client-page'
                        }}
                    />
                    <nav className="bottom-nav">
                        {previous && 
                            <Link to={previous.url} activeClassName="active" className="bottom-nav-item subnavigation" onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>{previous.name}</Link>
                        }
                        {previous === null &&
                            <Link to={`/client/${previousBackup.slug}`} activeClassName="active" className="bottom-nav-item subnavigation" onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>{previousBackup.name}</Link>
                        }
                        <Link to={`/client/${client.node.slug}`} activeClassName="active" className="bottom-nav-item subnavigation" onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>{client.node.name}</Link>
                        {next && 
                            <Link to={next.url} activeClassName="active" className="bottom-nav-item subnavigation" onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>{next.name}</Link>
                        }
                        {next === null &&
                            <Link to={`/client/${nextBackup.slug}`} activeClassName="active" className="bottom-nav-item subnavigation" onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>{nextBackup.name}</Link>
                        }
                    </nav>
                    <div className='client-logo' dangerouslySetInnerHTML={{ __html: client.node.logo }}></div>
                    <div className='client-blurb-overlay js-blurb' onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                        <div className='mobile-client-logo mobile-only' dangerouslySetInnerHTML={{ __html: client.node.logo }}></div>
                        <p className='lede'>{client.node.blurb}</p>
                        <p className='lede mobile-only'><a href="#" className='js-blurb-btn'>Close</a></p>
                        <div className='close-btn js-blurb-btn'></div>
                    </div>
                    <div className='client-blurb-mobile' onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                        <p className='lede'>{client.node.excerpt}... <a class="js-blurb-btn" href="#blurb"><strong>Read More</strong></a></p>
                    </div>
                    <div className='video-container'>
                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            slidesPerView={1}
                            loop={false}
                            speed={1000}
                            allowTouchMove={false}
                            direction="vertical"
                            modules={[Parallax]}
                            onSwiper={setControlledSwiper}
                            className={`client-carousel-video`}
                            >
                            {client.node.projects.map((item, index) => (
                                <SwiperSlide className="carousel-item">
                                    <Link to={`/project/${item.slug}`} className='client-carousel-video-container' onMouseEnter={EnteringView} onMouseLeave={LeavingView}>
                                        <video className="cover-image client-video" poster={item.image.background} loop muted autoplay='true' preload='metadata' playsInline>
                                            <source src={item.vimeo} type="video/mp4"/>
                                        </video>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className='background-container'>
                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            slidesPerView={1}
                            loop={false}
                            speed={1000}
                            followFinger={false}
                            mousewheel={true}
                            keyboard={{
                                enabled: true,
                                onlyInViewport: true
                            }}
                            direction="vertical"
                            effect="slide"
                            navigation={{
                                prevEl: '.navigation-prev',
                                nextEl: '.navigation-next',
                            }}
                            parallax={true}
                            modules={[Controller, Navigation, Mousewheel, Keyboard]}
                            controller={{ 
                                control: [controlledSwiper, controlledSwiperTitle],
                                by: 'container'
                            }}
                            className={`client-carousel-image`}
                            >
                            {client.node.projects.map((item, index) => (
                                <SwiperSlide className="carousel-item">
                                    <div className='client-carousel-image-container'>
                                        <img className="cover-image " src={item.image.background} alt='' />
                                    </div>
                                </SwiperSlide>
                            ))}
                            <div className={`navigation-prev`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                                <Arrow />
                            </div>
                            <div className={`navigation-next`} onMouseEnter={EnteringSmall} onMouseLeave={LeavingSmall}>
                                <Arrow />
                            </div>
                        </Swiper>
                    </div>
                    <div className='title-container'>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={false}
                            speed={1000}
                            allowTouchMove={false}
                            direction="vertical"
                            pagination={{
                                clickable: false,
                                type: 'fraction'
                            }}
                            modules={[Pagination]}
                            onSwiper={setControlledSwiperTitle}
                            className={`client-carousel-title`}
                            >
                            {client.node.projects.map((item, index) => (
                                <SwiperSlide className="client-carousel-title">
                                    <Link to={`/project/${item.slug}`} className='client-carousel-video-container' onMouseEnter={EnteringView} onMouseLeave={LeavingView}>
                                        <h4>{item.title}</h4>
                                    </Link>
                                </SwiperSlide>
                            ))}
                            <div className='arrow-inner'>
                            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="12" y="5" width="1" height="12" rx="0.5" transform="rotate(90 12 5)" fill="#DCFA6E"/>
                                <path d="M8 1L12 5.5L8 10" stroke="#DCFA6E" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </div>
                        </Swiper>
                    </div>
                </main>
            ))}
        </Layout>
    );
}
export default Client

export const query = graphql`
    query Client
    (
    $id: String!) 
    {
        client: allDatoCmsClient(filter: {id: {eq: $id}}, sort: {fields: position, order: ASC}) {
            edges {
                node {
                    name
                    slug
                    blurb
                    excerpt
                    logo
                    projects {
                        title
                        slug
                        vimeo
                        image {
                            background: url(imgixParams: {w: "1400", blendColor: "2A292D", blendMode: "normal", blendAlpha: 56})
                        }
                    }
                    seoMetaTags {
                        ...GatsbyDatoCmsSeoMetaTags
                    }
                }
                previous {
                    id
                    slug
                    name
                }
                next {
                    id
                    slug
                    name
                }
                }
            }
        all: allDatoCmsClient(sort: {fields: position, order: ASC}) {
            nodes {
                slug
                name
            }
        }
        }
`;